/* CSS for the table */
.adjustTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

th,
td {
    padding: 14px 0 5px 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

td {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    color: #655655;
}

th {
    background-color: #f2f2f2;
    font-family: "Public Sans", sans-serif;
}

.tableContainer {
    padding: 24px 31px;
    background-color: #fff;
    border-radius: 5px;
    width: 950px;
    box-shadow: 0 0 8px 2px #00000014;
    min-height: 400px;
}

@mixin buttonContainer() {
    display: flex;
    margin-top: 40px;
}

.previewButtonContainer {
    @include buttonContainer();
    justify-content: flex-end;
}

.reloadButtonContainer {
    @include buttonContainer();
    justify-content: center;
}

.alertContainer {
    width: 350px;
    word-break: break-word;
}

.alertMessage {
    margin-bottom: 24px;
    word-break: break-word;
}
