.collapsibleContent {
    overflow: hidden;
    transition: height 0.2s ease-in-out;
}

.titleText {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0078ab;
    cursor: pointer;
}

.collapsibleHeader {
    display: flex;
    padding: 2px 0 2px 0;
}

.collapsibleContentPadding {
    padding: 0;
}

.collapsibleIconButton {
    cursor: pointer;
    background-color: Transparent;
    background-repeat: no-repeat;
    border: none;
    overflow: hidden;
    outline: none;
}

.collapsibleCard {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.3s;
}

.breakdownItemsContainer {
    padding: 15px 10px;
    width: 313px;
    background-color: #f5f7f8;
    border-radius: 5px;
}

.nightContainer {
    display: flex;
    flex-direction: column;
}

.nightDateText {
    font-family: "Public Sans", sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
    padding: 2px 2px;
}

.nightDayText {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    padding: 2px 2px;
}

.inputBreakdown {
    height: 37px;
    width: 100%;
    padding: 5px 10px 5px 5px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    text-align: right;
}

.nightItem {
    display: grid;
    grid-template-columns: 1.5fr 1fr;
    align-items: center;
    margin-bottom: 5px;

    .text {
        margin-right: 10px;
    }
}

.overlay {
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
    opacity: 1;
    position: absolute;
    bottom: 0;
    height: 35px;
    pointer-events: none;
    right: 0;
    left: 0;
}
