// Import Bootstrap and its default variables
@import "../styles/fonts";

$white: #fff;
$midnight: #07384c;
$gray: #6f7578;
$gray-light: #dbdbdb;
$blue: #0178ab;

.app {
    padding: 25px 0 0 30px;
    width: 1440px;
}

/* Base class for containers */
@mixin container() {
    background-color: #fff;
    padding: 24px 31px;
    margin-bottom: 12px;
    border-radius: 4px;
}

/* Base class for buttons */
@mixin btn($bg, $color, $border, $hover, $active: $hover) {
    padding: 8px 24px;
    border-color: $border !important;
    border-width: 1px;
    border-radius: 4px;
    border-style: solid;
    min-width: fit-content;
    cursor: pointer;
    font-family: $public-sans;
    font-size: 16px;
    color: $color !important;
    background-color: $bg !important;

    &:hover {
        background-color: $color !important;
        color: $bg !important;
        border-color: $border !important;
        @if ($border == $bg) {
            border-color: $hover !important;
        }
    }
    &:focus {
        color: $color !important;
        border-color: $border !important;
        background-color: $bg !important;
        outline: none;
        box-shadow: none;
    }

    &:not(:disabled):not(.disabled):active {
        color: $bg !important;
        border-color: $border !important;
        background-color: $color !important;
        outline: none;
        box-shadow: none;
    }
}

/* Custom containers */
.panel-container-flex-header {
    @include container();
    display: flex;
    width: 950px;
    box-shadow: 0 0 5px 1px #00000014;
}

/* Custom buttons */
.save-button {
    @include btn($midnight, $white, $midnight, $midnight, $midnight);
    &:hover {
        background-color: $midnight !important;
        color: $white !important;
        border-color: $midnight !important;
    }
    width: 130px;
    font-weight: 400;
}

.back-button {
    @include btn($midnight, $white, $midnight, $midnight, $midnight);
    &:hover {
        background-color: $midnight !important;
        color: $white !important;
        border-color: $midnight !important;
    }
    width: 130px;
    font-weight: 400;
    margin-right: 10px;
}

/* Base class for inputs & select */
.select-container {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.select-label {
    font-family: $public-sans;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #2c3439;
    margin-bottom: 7px;
}

.select-options {
    box-sizing: border-box;
    border: 1px solid #e5ebed;
    border-radius: 8px;
    height: 52px;
    margin-bottom: 27px;
    color: #707579;
    padding: 0 16px;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    background-position: calc(100% - 0.75rem) 50% !important;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg height='10' width='10' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z'/%3E%3C/svg%3E")
        no-repeat 100% #fff;
}

/* Fonts */
.public-font-bold {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
}

/* Animations */
.fade-in {
    animation: fadeIn ease 1s;
    -webkit-animation: fadeIn ease 1s;
    -moz-animation: fadeIn ease 1s;
    -o-animation: fadeIn ease 1s;
    -ms-animation: fadeIn ease 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.loader {
    width: 30px;
    height: 30px;
    border: 5px solid #07384c;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

/* General */
/* CSS for the input in the last column for tables in Adjustments */
.input-column {
    height: 30px;
    width: 40%;
    padding: 5px 10px 5px 5px;
    background: #ffffff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #2c3439;
    text-align: right;
}

.text-right {
    text-align: right;
}

.title-right {
    text-align: right;
    padding-right: 10px;
}

.flex {
    display: flex;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-centered-horizontally {
    display: flex;
    justify-content: center;
}

.flex-centered-vertically {
    display: flex;
    align-items: center;
}
