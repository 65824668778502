.appeasementPanel {
    padding: 24px 31px;
    background-color: #fff;
    border-radius: 5px;
    width: 632px;
    box-shadow: 0 0 8px 2px #00000014;
    min-height: 400px;
    margin-bottom: 31px;
}

.appeasementContainer {
    width: 694px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
}

.savingChangesContainer {
    margin: 30px 0;
    display: flex;
    align-items: center;
}

.savingChangesMessage {
    margin-left: 16px;
    font-family: "Public Sans", sans-serif;
    font-weight: 300;
}

.submitButton {
    font-family: "Public Sans", sans-serif !important;
    width: 152px;
    height: 40px;
    padding: 8px 20px;
    margin-bottom: 20px;
}

.errorPanel {
    background-color: #ffe9e9;
    padding: 8px 20px;
    color: #c64040;
    border: 1px solid #ffafaf;
    border-radius: 8px;
}
