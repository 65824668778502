/* Header Item */
.title {
    display: inline-block;
    width: 92px;
    font-family: "Public Sans", sans-serif;
}

.itemValue {
    font-family: "Public Sans", sans-serif;
    margin-left: 20px;
    & a {
        text-decoration: none;
        color: #0078ab;
    }
}
