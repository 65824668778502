/* Header Information */
.leftPanel {
    line-height: 27px;
}

.rightPanel {
    margin-left: 210px;
    line-height: 27px;
}

.headerTitle {
    font-family: "Public Sans", sans-serif;
    margin: 20px 0;
    font-weight: 700;
    font-size: 32px;
}
