.searchBarContainer {
    display: flex;
    flex-direction: column;
    margin: 20px 0;
}

.title {
    font-family: "Public Sans", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #2c3439;
    margin-bottom: 7px;
}

.selectOptions {
    box-sizing: border-box;
    margin-bottom: 27px;
    color: #707579;
    -webkit-appearance: none !important;
    appearance: none !important;
}
