/* CSS for the table */
.adjustTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.tableDataCell {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    color: #655655;
    padding: 33px 0 5px 14px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.highlightedTr {
    font-weight: bold;
}

.tableHeaderCell {
    background-color: #f2f2f2;
    font-family: "Public Sans", sans-serif;
}

.tableContainer {
    padding: 24px 31px;
    background-color: #fff;
    border-radius: 5px;
    width: 950px;
    box-shadow: 0 0 8px 2px #00000014;
    min-height: 400px;
}

.persistingMessage {
    margin-right: 10px;
}

.buttonContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 60px;
}

.messageContainer {
    font-family: "Public Sans", sans-serif;
    flex-grow: 0;
    display: flex;
    align-items: center;
}

.buttons {
    flex-grow: 0;
}

.negative {
    color: #d62e4f;
    margin-left: 5px;
}

.positive {
    color: #6ba342;
    margin-left: 5px;
}
