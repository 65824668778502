.title {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #2c3439;
}

.lineItemsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.lineItemTitle {
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    width: 300px;
}

.lineItemValue {
    height: 40px;
    width: 330px;
    padding: 5px 10px 5px 5px;
    background: #fff;
    opacity: 0.7;
    border: 1px solid #b2c2c8;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: #000000;
    text-align: right;
}

.breakdownSection {
    margin-top: 8px;
}

.breakdownText {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    color: #0078ab;
    cursor: pointer;
}

.icon {
    filter: brightness(0) saturate(100%) invert(28%) sepia(67%) saturate(1963%) hue-rotate(173deg) brightness(96%) contrast(101%);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.totalsSection {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.lineItemTotalTitle {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
}
