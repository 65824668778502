.trashIconError {
    align-self: center;
    filter: invert(25%) sepia(83%) saturate(2197%) hue-rotate(329deg) brightness(88%) contrast(91%);
}

.reloadButtonContainer {
    display: flex;
    justify-content: center;
}

.reservationError {
    word-break: break-word;
    text-align: center;
}
