.backElement {
    font-family: "Public Sans", sans-serif;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 60px;
}

.arrow {
    vertical-align: middle;
}
