.headerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 800px;
    align-items: end;
    margin-bottom: 60px;

    .headerTitle {
        font-family: "Public Sans", sans-serif;
        font-weight: 800;
        font-size: 40px;
        margin: 0;
        padding: 0;
        width: 600px;
    }

    .headerItemsContainer {
        display: grid;
        grid-template-columns: 0.5fr 2fr;
        width: 600px;
        margin-left: 180px;
    }

    .headerItemTitle {
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #2c3439;
        margin-right: 30px;
    }

    .headerItemValue {
        font-family: "Public Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0;
        text-align: left;
        color: #2c3439;
        & a {
            text-decoration: none;
            color: #0078ab;
            cursor: pointer;
        }
    }
}
