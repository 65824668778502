.title {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #2c3439;
}

.lineItemsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
}

.lineItemBox {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 27px;
    background: #fff;
    opacity: 0.7;
    border: 1px solid #e5ebed;
    box-sizing: border-box;
    border-radius: 8px;
}

.lineItemBoxDisabled {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 27px;
    background: #f9f9f9;
    opacity: 0.7;
    border: 1px solid #e5ebed;
    box-sizing: border-box;
    border-radius: 8px;
}

.lineItemSufix {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    left: 10px;
    top: 10px;
    padding: 0px 10px;
}

.lineItemTitle {
    font-family: "Public Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #2c3439;
    width: 100%;
}

.lineItemValue {
    height: 52px;
    width: 100%;
    padding: 0 16px;
    -webkit-appearance: none !important;
    appearance: none !important;
    padding-right: 2rem !important;
    font-style: normal;
    font-weight: 400;
    border: none;
    font-size: 14px;
    border-radius: 8px;
    line-height: 120%;
    color: #000000;
    text-align: left;
}
