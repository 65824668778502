.resetFinancePanel {
    padding: 24px 31px;
    background-color: #fff;
    border-radius: 5px;
    width: 632px;
    box-shadow: 0 0 8px 2px #00000014;
    min-height: 400px;
}

.hrDivider {
    border: 1px solid #f2f2f2;
    width: 632px;
    display: flex;
    margin: 30px 0;
}

.reason {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    line-height: 28.8px;
    color: #2c3439;
}

.selectContainer {
    display: flex;
}

.buttonsSection {
    margin-top: 25px;
    margin-bottom: 45px;
    width: 632px;
    display: flex;
    justify-content: flex-end;
    padding: 8px 58px;
}

.undoChangesButton {
    font-family: "Public Sans", sans-serif !important;
    width: 152px;
    height: 40px;
    margin-right: 20px;
    padding: 8px 20px;
}

.submitButton {
    font-family: "Public Sans", sans-serif !important;
    width: 152px;
    height: 40px;
    padding: 8px 20px;
}

.headerContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 20px;
}

.headerItem {
    font-family: "Public Sans", sans-serif;
    padding-top: 10px;
    padding-bottom: 10px;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #2c3439;
    background: #e5ebed;
    box-shadow: 0 -1px 0 #b2c2c8, 0 1px 0 #b2c2c8;
    text-align: left;
    margin-bottom: 16px;
    padding-left: 15px;
}

.titlePreviewItem {
    font-family: "Public Sans", sans-serif;
    font-weight: 700;
    font-size: 21px;
    color: #2c3439;
}

.itemRowContainer {
    font-family: "Public Sans", sans-serif;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: left;
    margin-bottom: 16px;
    align-items: center;
}

.itemRowValues {
    font-family: "Public Sans", sans-serif;
    padding-left: 15px;
    padding-top: 14px;
    padding-bottom: 12px;
    background: #f5f7f8;
    border-radius: 4px;
    font-size: 14px;
    margin: 0 5px 0 5px;
}

.itemRowText {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0 5px 0 5px;
}

.itemsContainer > div:first-child {
    margin-top: 0;
}

.itemsContainer > div:not(:first-child) {
    margin-top: 55px;
}

.financeContainer {
    & div:last-of-type div {
        font-weight: 900;
    }
}

.difference {
    margin-left: 40px;
    font-weight: 400;
}

.nightContainer {
    display: flex;
    flex-direction: column;
}

.nightDateText {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    line-height: 16px;
    font-weight: 300;
    padding: 2px 2px;
}

.nightDayText {
    font-family: "Public Sans", sans-serif;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 2px 2px;
}

.savingChangesContainer {
    margin: 30px 0;
    display: flex;
    align-items: center;
}

.savingChangesMessage {
    margin-left: 16px;
    font-family: "Public Sans", sans-serif;
    font-weight: 300;
}

.alertMessage {
    margin-bottom: 24px;
    word-break: break-word;
}
